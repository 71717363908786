html {
  overflow-x: hidden;
  position: relative;
}

#__next {
  overflow-x: hidden;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  /* 62.5% of 16px = 10px */
  overflow-x: hidden;
  position: relative;
}

.Typewriter {
  display: inline;
}

/* Recaptcha */
/* Hide on mobile */
@media (max-width: 1199px) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

.ari10-widget-popup-wrapper {
  position: relative;
  z-index: 99999;
}